:root {
  --version: 1.39;
}

div#tarteaucitronRoot {
  display: -webkit-box;
  display: flex;
}
@media (min-width: 768px) {
  div#tarteaucitronRoot {
    -webkit-box-pack: center;
            justify-content: center;
    height: 100%;
    position: fixed;
    z-index: 1200;
    pointer-events: none;
  }
  div#tarteaucitronRoot > div, div#tarteaucitronRoot > button {
    pointer-events: all;
  }
}
div#tarteaucitronRoot * {
  outline: none;
  box-sizing: border-box !important;
}
div#tarteaucitronRoot div#tarteaucitronAlertBig {
  color: #ffffff !important;
  top: auto !important;
  padding: 25px 0 !important;
  border-radius: 10px !important;
  box-shadow: 0 0 20px rgba(6, 52, 98, 0.3) !important;
  max-width: 520px !important;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
          flex-direction: row !important;
  flex-wrap: wrap !important;
  -webkit-box-pack: center !important;
          justify-content: center !important;
  align-content: flex-start !important;
  -webkit-box-align: center !important;
          align-items: center !important;
  background-color: #ffffff !important;
}
@media (min-width: 768px) {
  div#tarteaucitronRoot div#tarteaucitronAlertBig {
    position: relative;
  }
}
div#tarteaucitronRoot div#tarteaucitronAlertBig:before {
  font-size: 22px;
  display: block !important;
  text-align: left !important;
  padding: 0 30px !important;
  -webkit-box-ordinal-group: 2 !important;
          order: 1 !important;
  -webkit-box-flex: 1 !important;
          flex: 1 1 100% !important;
  align-self: auto !important;
  color: #063462 !important;
}
div#tarteaucitronRoot div#tarteaucitronAlertBig button {
  display: inline-block !important;
  width: auto !important;
  border-radius: 4px !important;
  padding: 8px 16px !important;
  margin: 0 4px !important;
}
div#tarteaucitronRoot div#tarteaucitronAlertBig button:nth-of-type(1) {
  -webkit-box-ordinal-group: 6 !important;
          order: 5 !important;
  -webkit-box-flex: 0 !important;
          flex: 0 0 25% !important;
  align-self: center !important;
}
div#tarteaucitronRoot div#tarteaucitronAlertBig button:nth-of-type(2) {
  -webkit-box-ordinal-group: 5 !important;
          order: 4 !important;
  -webkit-box-flex: 0 !important;
          flex: 0 0 25% !important;
  align-self: center !important;
}
div#tarteaucitronRoot div#tarteaucitronAlertBig button:nth-of-type(3) {
  -webkit-box-ordinal-group: 4 !important;
          order: 3 !important;
  -webkit-box-flex: 0 !important;
          flex: 0 0 25% !important;
  align-self: center !important;
}
div#tarteaucitronRoot div#tarteaucitronAlertBig span#tarteaucitronDisclaimerAlert {
  text-align: left !important;
  background-color: #ffffff !important;
  padding: 0 30px !important;
  -webkit-box-ordinal-group: 3 !important;
          order: 2 !important;
  -webkit-box-flex: 1 !important;
          flex: 1 1 100% !important;
  align-self: auto !important;
  color: #063462 !important;
  font-family: SourceSansPro, sans-serif !important;
  font-size: 14px !important;
  margin: 10px 0 30px;
  display: block;
}
div#tarteaucitronRoot div#tarteaucitronInfo {
  color: #ffffff !important;
}
div#tarteaucitronRoot button.tarteaucitronAllow {
  background-color: #063462 !important;
  color: #ffffff !important;
}
div#tarteaucitronRoot .tarteaucitronCheck:before {
  color: #ffffff !important;
}
div#tarteaucitronRoot button.tarteaucitronDeny {
  background-color: #063462 !important;
  color: #ffffff !important;
  margin-left: 10px !important;
}
div#tarteaucitronRoot .tarteaucitronCross:before {
  color: #ffffff !important;
}
div#tarteaucitronRoot div#tarteaucitronCloseAlert {
  background-color: #ffffff !important;
  color: #063462 !important;
}
div#tarteaucitronRoot div#tarteaucitronPrivacyUrl {
  background-color: #063462 !important;
  color: #ffffff !important;
}
div#tarteaucitronRoot li.tarteaucitronLine.tarteaucitronIsAllowed {
  border-color: #3e9187 !important;
}
div#tarteaucitronRoot li.tarteaucitronLine.tarteaucitronIsDenied {
  border-color: #ff3944 !important;
}
div#tarteaucitronRoot div#tarteaucitronDotGreen {
  background-color: #3e9187 !important;
}
div#tarteaucitronRoot div#tarteaucitronDotRed {
  background-color: #ff3944 !important;
}

.tarteaucitronCheck {
  display: none;
}

.tarteaucitronCross {
  display: none;
}

div#tarteaucitronRoot.tarteaucitronBeforeVisible div#tarteaucitronAlertBig {
  display: -webkit-box !important;
  display: flex !important;
}

span.tarteaucitron-magic-block {
  background: #063462;
  display: inline-block;
  padding: 25px;
  color: #ffffff;
  text-align: center;
  max-width: 400px;
  font-size: 14px;
}
span.tarteaucitron-magic-block b {
  font-size: 17px;
}
span.tarteaucitron-magic-block button {
  margin-top: 15px;
}

div#tarteaucitron div#tarteaucitronServices .tarteaucitronMainLine {
  border-color: #063462 !important;
  background: #063462 !important;
  color: #ffffff !important;
}
div#tarteaucitron div#tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName a {
  color: #ffffff !important;
}
div#tarteaucitron div#tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName b {
  color: #ffffff !important;
}
div#tarteaucitron div#tarteaucitronServices .tarteaucitronMainLine:hover {
  background: #063462 !important;
  color: #ffffff !important;
}
div#tarteaucitron div#tarteaucitronServices .tarteaucitronTitle {
  background: #063462 !important;
  color: #ffffff !important;
}
div#tarteaucitron div#tarteaucitronServices .tarteaucitronTitle a {
  color: #ffffff !important;
}
div#tarteaucitron div#tarteaucitronServices .tarteaucitronTitle button {
  background: #063462 !important;
  color: #ffffff !important;
}
div#tarteaucitron div#tarteaucitronServices .tarteaucitronLine {
  background: rgba(6, 52, 98, 0.1) !important;
}
div#tarteaucitron div#tarteaucitronServices .tarteaucitronLine:hover {
  background: rgba(6, 52, 98, 0.2) !important;
}
div#tarteaucitron div#tarteaucitronServices .tarteaucitronHidden {
  background: rgba(6, 52, 98, 0.07) !important;
}
div#tarteaucitron div#tarteaucitronServices .tarteaucitronDetails {
  background: #063462 !important;
  color: #ffffff !important;
}
div#tarteaucitron .tarteaucitronBorder {
  border-color: #063462 !important;
}
div#tarteaucitron div#tarteaucitronInfo {
  background: #063462 !important;
  color: #ffffff !important;
}
div#tarteaucitron div#tarteaucitronInfo a {
  color: #ffffff !important;
}
div#tarteaucitron .tarteaucitronH1 {
  color: #ffffff !important;
}
div#tarteaucitron .tarteaucitronName .tarteaucitronH2 {
  color: #ffffff !important;
}
div#tarteaucitron .tarteaucitronTitle button {
  color: #ffffff !important;
}
div#tarteaucitron div#tarteaucitronClosePanel {
  background: #063462 !important;
  color: #ffffff !important;
}

#tarteaucitronAlertSmall div#tarteaucitronCookiesListContainer div#tarteaucitronCookiesList .tarteaucitronCookiesListMain {
  background: rgba(6, 52, 98, 0.1) !important;
}
#tarteaucitronAlertSmall div#tarteaucitronCookiesListContainer div#tarteaucitronCookiesList .tarteaucitronCookiesListMain:hover {
  background: rgba(6, 52, 98, 0.2) !important;
}

div#tarteaucitronRoot div#tarteaucitron .tarteaucitronHidden:hover ul li {
  background: rgba(6, 52, 98, 0.2) !important;
}

div#tarteaucitronAlertSmall {
  background: #063462 !important;
  color: #ffffff !important;
}
div#tarteaucitronAlertSmall div#tarteaucitronCookiesListContainer div#tarteaucitronCookiesList {
  border-color: #063462 !important;
}
div#tarteaucitronAlertSmall div#tarteaucitronCookiesListContainer div#tarteaucitronCookiesList .tarteaucitronHidden {
  background: rgba(6, 52, 98, 0.07) !important;
  border-color: #063462 !important;
}
div#tarteaucitronAlertSmall div#tarteaucitronCookiesListContainer div#tarteaucitronCookiesList .tarteaucitronCookiesListMain {
  border-color: #063462 !important;
}
div#tarteaucitronAlertSmall div#tarteaucitronCookiesListContainer div#tarteaucitronCookiesList .tarteaucitronTitle {
  background: #063462 !important;
  color: #ffffff !important;
}
div#tarteaucitronAlertSmall div#tarteaucitronCookiesListContainer div#tarteaucitronCookiesTitle {
  background: #063462 !important;
  color: #ffffff !important;
}
div#tarteaucitronAlertSmall div#tarteaucitronCookiesListContainer div#tarteaucitronCookiesTitle b {
  color: #ffffff !important;
}
div#tarteaucitronAlertSmall div#tarteaucitronCookiesListContainer div#tarteaucitronCookiesTitle:hover {
  background: #063462 !important;
  color: #ffffff !important;
}
div#tarteaucitronAlertSmall div#tarteaucitronCookiesListContainer div#tarteaucitronClosePanelCookie {
  background: #063462 !important;
  color: #ffffff !important;
}
div#tarteaucitronAlertSmall div#tarteaucitronCookiesNumber {
  color: #ffffff !important;
}
div#tarteaucitronAlertSmall div#tarteaucitronManager {
  color: #ffffff !important;
}

.tarteaucitron-toggle-group {
  background: rgba(6, 52, 98, 0.1) !important;
}

.tac_activate {
  background: #063462 !important;
  color: #ffffff !important;
}
.tac_activate .tac_float {
  background: #063462 !important;
  color: #ffffff !important;
}
.tac_activate .tac_float b {
  background: #063462 !important;
  color: #ffffff !important;
}
.tac_activate .tac_float .tarteaucitronAllow {
  background-color: #063462 !important;
  color: #ffffff !important;
}

div#tarteaucitronServices {
  background: #063462 !important;
  color: #ffffff !important;
}

head .tarteaucitron-magic-block button {
  display: none;
}

.tarteaucitron-magic-block button {
  border-radius: 4px;
  background-color: #063462 !important;
  cursor: pointer;
  display: inline-block;
  padding: 6px 10px;
  text-align: center;
  text-decoration: none;
  width: auto;
  border: 0;
  cursor: pointer;
  color: #ffffff !important;
}

#tarteaucitronDisclaimerAlert p,
#tarteaucitronDisclaimerAlert li,
#tarteaucitronDisclaimerAlert strong,
#tarteaucitronDisclaimerAlert a {
  color: #063462 !important;
  font-size: 12px !important;
  text-align: justify !important;
}
#tarteaucitronDisclaimerAlert p {
  margin-top: 5px !important;
  line-height: 1.3 !important;
}
#tarteaucitronDisclaimerAlert ul {
  padding-left: 2rem !important;
}
#tarteaucitronDisclaimerAlert ul .doted {
  list-style-type: disc;
}
#tarteaucitronDisclaimerAlert ul li {
  margin-top: 5px !important;
}
#tarteaucitronDisclaimerAlert strong {
  font-weight: bold;
}

@media (max-width: 575.98px) {
  div#tarteaucitronRoot div#tarteaucitronAlertBig {
    width: 100% !important;
    left: 0 !important;
    bottom: 0 !important;
    border-radius: 0 !important;
    box-shadow: 0 -10px 20px rgba(6, 52, 98, 0.3) !important;
    padding: 20px 20px 0 20px !important;
  }
  div#tarteaucitronRoot div#tarteaucitronAlertBig button {
    display: block !important;
    width: -webkit-calc(100% - 60px) !important;
    width: calc(100% - 60px) !important;
    margin: 0 10px 10px !important;
  }
  div#tarteaucitronRoot div#tarteaucitronAlertBig button:nth-of-type(1) {
    -webkit-box-flex: 1 !important;
            flex: 1 1 40% !important;
    -webkit-box-ordinal-group: 5 !important;
            order: 4 !important;
  }
  div#tarteaucitronRoot div#tarteaucitronAlertBig button:nth-of-type(2) {
    -webkit-box-flex: 1 !important;
            flex: 1 1 40% !important;
    -webkit-box-ordinal-group: 4 !important;
            order: 3 !important;
  }
  div#tarteaucitronRoot div#tarteaucitronAlertBig button:nth-of-type(3) {
    -webkit-box-ordinal-group: 6 !important;
            order: 5 !important;
    text-decoration: underline !important;
    font-size: 14px !important;
    -webkit-box-flex: 1 !important;
            flex: 1 1 100% !important;
  }
  div#tarteaucitronRoot div#tarteaucitronAlertBig span#tarteaucitronDisclaimerAlert {
    padding: 0 !important;
    margin-bottom: 20px !important;
    font-size: 12px !important;
  }
  div#tarteaucitronRoot div#tarteaucitronAlertBig:before {
    padding: 0 !important;
    font-size: 18px !important;
  }
}
@media (min-width: 768px) {
  div#tarteaucitronRoot div#tarteaucitronAlertBig {
    bottom: 0;
    position: absolute;
    border-radius: 0 !important;
    max-width: 100% !important;
    -webkit-box-pack: unset !important;
            justify-content: unset !important;
  }
  div#tarteaucitronRoot div#tarteaucitronAlertBig button#tarteaucitronPersonalize2 {
    -webkit-box-flex: 0 !important;
            flex: 0 0 10% !important;
  }
  div#tarteaucitronRoot div#tarteaucitronAlertBig:before {
    margin-bottom: 7px;
  }
  div#tarteaucitronRoot div#tarteaucitronAlertBig button#tarteaucitronAllDenied2 {
    position: absolute;
    left: 690px;
    top: 20px;
    font-size: 14px !important;
    background-color: #ffffff !important;
    color: #063462 !important;
    text-decoration: underline;
  }
  div#tarteaucitronRoot div#tarteaucitronAlertBig button#tarteaucitronCloseAlert {
    -webkit-box-flex: 0 !important;
            flex: 0 0 10% !important;
    border: 2px solid #063462 !important;
    float: right;
  }
  div#tarteaucitronRoot div#tarteaucitronAlertBig span#tarteaucitronDisclaimerAlert {
    max-width: 78%;
    float: left;
    margin: 0;
  }
}
@media (min-width: 768px) and (max-width: 1300px) {
  div#tarteaucitronRoot div#tarteaucitronAlertBig span#tarteaucitronDisclaimerAlert {
    max-width: 76%;
  }
}
@media (min-width: 768px) and (max-width: 1076px) {
  div#tarteaucitronRoot div#tarteaucitronAlertBig span#tarteaucitronDisclaimerAlert {
    max-width: 72%;
  }
}
@media (min-width: 768px) and (max-width: 875px) {
  div#tarteaucitronRoot div#tarteaucitronAlertBig span#tarteaucitronDisclaimerAlert {
    max-width: 67%;
  }
}
@media (max-width: 575.98px) {
  div#tarteaucitronRoot div#tarteaucitronAlertBig button#tarteaucitronAllDenied2 {
    position: absolute;
    top: 12px;
    font-size: 14px !important;
    background-color: #ffffff !important;
    color: #063462 !important;
    width: 100% !important;
    -webkit-box-flex: 0 !important;
            flex: 0 0 80% !important;
    text-align: center;
    text-decoration: underline;
  }
  div#tarteaucitronRoot div#tarteaucitronAlertBig:before {
    margin-top: 27px;
  }
  div#tarteaucitronRoot div#tarteaucitronAlertBig button:nth-of-type(3) {
    -webkit-box-flex: 0 !important;
            flex: 0 0 80% !important;
    border: 2px solid #063462 !important;
    text-decoration: none !important;
  }
  div#tarteaucitronRoot div#tarteaucitronAlertBig button:first-of-type {
    -webkit-box-flex: 0 !important;
            flex: 0 0 80% !important;
  }
}
